import { toast } from 'react-toastify';

const bundle = {
	name: 'pictureBooks',
	doFetchPictureBooks: params => ({ core }) => {
		return core.fetchPictureBooks(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchPictureBookDetail: id => ({ core }) => {
		return core.fetchPictureBookDetail(id);
	},
	doAddAndUpdatePictureBook: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddPictureBook(params.data);
		}
		return core.doUpdatePictureBook(params.data);
	},
	doDeletePictureBook: id => ({ core }) => {
		return core.doDeletePictureBook(id);
	},
};

export default bundle;
