import { toast } from 'react-toastify';

const bundle = {
	name: 'karaokeSongs',
	doFetchKaraokeSongs: params => ({ core }) => {
		return core.fetchKaraokeSongs(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchKaraokeSongDetail: id => ({ core }) => {
		return core.fetchKaraokeSongDetail(id);
	},
	doAddAndUpdateKaraokeSong: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddKaraokeSong(params.data);
		}

		return core.doUpdateKaraokeSong(params.data);
	},
	doDeleteKaraokeSong: id => ({ core }) => {
		return core.doDeleteKaraokeSong(id);
	},
};

export default bundle;
