import loadable from '@/components/Loadable';

export const Login = loadable(() => import('@/pages/LoginPage'));
export const UserLogin = loadable(() => import('@/pages/UserLoginPage'));
export const DeActiveUserPage = loadable(() =>
  import('@/pages/DeActiveUserPage'),
);
export const DeleteAccountInstruction = loadable(() =>
  import('@/pages/DeleteAccountInstruction'),
);
export const Home = loadable(() => import('@/pages/HomePage'));

export const Users = loadable(() => import('@/pages/Users'));
export const UserDetail = loadable(() => import('@/pages/UserDetail'));

export const DigitalContents = loadable(() =>
	import('@/pages/DigitalContents'),
);

export const Events = loadable(() => import('@/pages/Events'));
export const EventDetail = loadable(() => import('@/pages/EventDetail'));

export const MusicAlbums = loadable(() => import('@/pages/MusicAlbums'));
export const MusicAlbumDetail = loadable(() =>
	import('@/pages/MusicAlbumDetail'),
);

export const AudioBooks = loadable(() => import('@/pages/AudioBooks'));
export const AudioBookDetail = loadable(() =>
	import('@/pages/AudioBookDetail'),
);

export const KaraokeSongs = loadable(() => import('@/pages/KaraokeSongs'));

export const VideoAlbums = loadable(() => import('@/pages/VideoAlbums'));
export const VideoAlbumDetail = loadable(() =>
	import('@/pages/VideoAlbumDetail'),
);

export const SongBooks = loadable(() => import('@/pages/SongBooks'));
export const SongBookDetail = loadable(() => import('@/pages/SongBookDetail'));

export const PictureBooks = loadable(() => import('@/pages/PictureBooks'));
export const PictureBookDetail = loadable(() =>
	import('@/pages/PictureBookDetail'),
);

export const InAppProducts = loadable(() => import('@/pages/InAppProducts'));

export const Headlines = loadable(() => import('@/pages/Headlines'));

export const SentNotifications = loadable(() =>
	import('@/pages/SentNotifications'),
);
export const ScheduledNotifications = loadable(() =>
	import('@/pages/ScheduledNotifications'),
);

export const NotAllow = loadable(() => import('@/pages/NotAllow'));
export const NotFound = loadable(() => import('@/pages/NotFound'));
