import { createRouteBundle, createSelector } from 'redux-bundler';

const bundle = createRouteBundle({
	'/': {
		name: 'Home',
	},
	'/login': {
		name: 'Login',
	},
	'/user-login': {
		name: 'UserLogin',
	},
	'/deactive': {
		name: 'DeActiveUserPage',
	},
	'/delete-account-instruction': {
		name: 'DeleteAccountInstruction',
	},
	'/users': {
		name: 'Users',
	},
	'/users?*': {
		name: 'Users',
	},
	'/user-detail/:id': {
		name: 'UserDetail',
	},
	'/digital-contents': {
		name: 'DigitalContents',
	},
	'/events': {
		name: 'Events',
	},
	'/event-detail/:id': {
		name: 'EventDetail',
	},
	'/music-albums': {
		name: 'MusicAlbums',
	},
	'/music-album-detail/:id': {
		name: 'MusicAlbumDetail',
	},
	'/audio-books': {
		name: 'AudioBooks',
	},
	'/audio-book-detail/:id': {
		name: 'AudioBookDetail',
	},
	'/karaokes': {
		name: 'KaraokeSongs',
	},
	'/video-albums': {
		name: 'VideoAlbums',
	},
	'/video-album-detail/:id': {
		name: 'VideoAlbumDetail',
	},
	'/song-books': {
		name: 'SongBooks',
	},
	'/song-book-detail/:id': {
		name: 'SongBookDetail',
	},
	'/picture-books': {
		name: 'PictureBooks',
	},
	'/picture-book-detail/:id': {
		name: 'PictureBookDetail',
	},
	'/in-app-products': {
		name: 'InAppProducts',
	},
	'/headlines': {
		name: 'Headlines',
	},
	'/sent-notifications': {
		name: 'SentNotifications',
	},
	'/scheduled-notifications': {
		name: 'ScheduledNotifications',
	},
	'/not-allow': {
		name: 'NotAllow',
	},
	'*': {
		name: 'NotFound',
	},
});

bundle.selectRouteName = createSelector('selectRoute', route => route.name);

export default bundle;
