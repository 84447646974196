import { toast } from 'react-toastify';

const bundle = {
	name: 'eventCategories',
	doFetchEventCategories: () => ({ core }) => {
		return core.fetchEventCategories().then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doAddAndUpdateEventCategory: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddEventCategory({ body: params.data });
		}
		return core.doUpdateEventCategory({ body: params.data });
	},
	doDeleteEventCategory: id => ({ core }) => {
		return core.doDeleteEventCategory(id);
	},
	doGetEventCategoryDetail: id => ({ core }) => {
		return core.fetchEventCategory(id);
	},
};

export default bundle;
