import { toast } from 'react-toastify';

const bundle = {
	name: 'events',
	doFetchEvents: params => ({ core }) => {
		return core.fetchEvents(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchEventDetail: id => ({ core }) => {
		return core.fetchEventDetail(id);
	},
	doAddAndUpdateEvent: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddEvent({ body: params.data });
		}
		return core.doUpdateEvent({ body: params.data });
	},
	doDeleteEvent: id => ({ core }) => {
		return core.doDeleteEvent(id);
	},
};

export default bundle;
