const apiUrls = {
	user: {
		login: '/user/login',
		userLogin: '/user/sign-in-mobile',
		deActiveUser: '/user/deactivate',
		logout: '/user/logout',
		getUsers: '/users',
		user: '/user',
		forgotPassword: '/user/forgot-password',
		resetPassword: '/user/reset-password',
		changePassword: '/user/change-password',
	},
	digitalContents: {
		getCategories: '/digital-content-categories',
		category: '/digital-content-category',
		getContents: '/digital-contents',
		content: '/digital-content',
	},
	file: {
		upload: '/file/upload-public',
		audioFileUpload: '/audio-file',
		audioFiledelete: '/audio-file',
		videoFile: '/video-file',
	},
	events: {
		getEventCategories: '/event-categories',
		eventCategory: '/event-category',
		getEvents: '/events',
		event: '/event',
	},
	eventTickets: {
		eventTicket: '/event-ticket',
	},
	eventImage: {
		getEventImages: '/event-images',
		eventImage: '/event-image',
	},
	musicAlbums: {
		getMusicAlbums: '/music-albums',
		musicAlbum: '/music-album',
	},
	audioBooks: {
		getAudioBooks: '/audio-book-albums',
		audioBook: '/audio-book-album',
	},
	karaokeSongs: {
		getKaraokeSongs: '/karaoke-songs',
		karaokeSong: '/karaoke-song',
	},
	videoAlbums: {
		getVideoAlbums: '/video-albums',
		videoAlbum: '/video-album',
	},
	songBooks: {
		getSongBooks: '/song-books',
		songBook: '/song-book',
	},
	pictureBooks: {
		getPictureBooks: '/picture-books',
		pictureBook: '/picture-book',
	},
	products: {
		getProducts: '/products',
		product: '/product',
	},
	headlines: {
		getHeadlines: '/headlines',
		headline: '/headline',
	},
	notifications: {
		getNotifications: '/notifications',
		notification: '/notification',
	},
};

export default apiUrls;
