import { toast } from 'react-toastify';

const bundle = {
	name: 'notifications',
	doFetchNotifications: params => ({ core }) => {
		return core.fetchNotifications(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doAddAndUpdateNotification: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddNotification(params.data);
		}
		return core.doUpdateNotification(params.data, params.id);
	},
	doDeleteNotification: id => ({ core }) => {
		return core.doDeleteNotification(id);
	},
};

export default bundle;
