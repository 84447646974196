import { toast } from 'react-toastify';

const bundle = {
	name: 'audioBooks',
	doFetchAudioBooks: params => ({ core }) => {
		return core.fetchAudioBooks(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchAudioBookDetail: id => ({ core }) => core.fetchAudioBookDetail(id),
	doAddAndUpdateAudioBook: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddAudioBook(params.data);
		}
		return core.doUpdateAudioBook(params.data);
	},
	doDeleteAudioBook: id => ({ core }) => core.doDeleteAudioBook(id),
	doAddAndEditAudioBookAlbumFile: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddAudioBookAlbumFile(params.data);
		}
		return core.doUpdateAudioBookAlbumFile(params.data);
	},
};

export default bundle;
