import { toast } from 'react-toastify';

const bundle = {
	name: 'videoAlbums',
	doFetchVideoAlbums: params => ({ core }) => {
		return core.fetchVideoAlbums(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchVideoAlbumDetail: id => ({ core }) => {
		return core.fetchVideoAlbumDetail(id);
	},
	doAddAndUpdateVideoAlbum: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddVideoAlbum(params.data);
		}

		return core.doUpdateVideoAlbum(params.data);
	},
	doDeleteVideoAlbum: id => ({ core }) => {
		return core.doDeleteVideoAlbum(id);
	},
	doAddAndEditVideoFile: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddVideoFile(params.data);
		}
		return core.doUpdateVideoFile(params.data);
	},
};

export default bundle;
