import { toast } from 'react-toastify';

const initialState = {
	accessToken: null,
	infoLogin: {},
	userLoginData: {},
	userLoginAccessToken: null,
};

export default {
	name: 'login',
	persistActions: ['UPDATE_ACCESS_TOKEN'],
	getReducer: () => {
		return (state = initialState, { type, payload }) => {
			if (type === 'USER_LOGIN_SUCCESS') {
				return Object.assign({}, state, { infoLogin: payload || {} });
			}
			if (type === 'UPDATE_ACCESS_TOKEN') {
				return Object.assign({}, state, { accessToken: payload || null });
			}

			if (type === 'USER_USER_LOGIN_SUCCESS') {
				return Object.assign({}, state, { userLoginData: payload || {} });
			}
	
			if (type === 'USER_UPDATE_ACCESS_TOKEN') {
				return Object.assign({}, state, {
					userLoginAccessToken: payload || null,
				});
			}

			return state;
		};
	},
	selectInfoLogin: state => state.login.infoLogin,
	selectUserLoginToken: state => state.login.accessToken,
	selectIsLoggedIn: state => !!state.login.accessToken,
	selectUserLoginData: (state) => state.login.userLoginData,
	selectUserLoginTokenData: (state) => state.login.userLoginAccessToken,
	selectIsUserLoggedIn: (state) => !!state.login.userLoginAccessToken,
	doLogin: params => ({ dispatch, core }) => {
		return core.doLogin(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
				return res;
			} else {
				toast.success(res.message);

				dispatch({ type: 'USER_LOGIN_SUCCESS', payload: res.data });
				return dispatch({
					type: 'UPDATE_ACCESS_TOKEN',
					payload: res.data.accessToken,
				});
			}
		});
	},
	doUserLogin:
		(params) =>
		({ dispatch, core }) => {
		return core.doUserLogin(params).then((res) => {
			if (res.code !== 200) {
			toast.error(res.message);
			return res;
			} else {
			toast.success(res.message);

			dispatch({ type: 'USER_USER_LOGIN_SUCCESS', payload: res.data });

			return dispatch({
				type: 'USER_UPDATE_ACCESS_TOKEN',
				payload: res.data.accessToken,
			});
			}
		});
		},
	doDeActiveUser:
		(params) =>
		({ dispatch, store, core }) => {
		return core.doDeActiveUser(params).then((res) => {
			if (res.code !== 200) {
			toast.error(res.message);
			return res;
			} else {
			toast.success(res.message);

			dispatch({ type: 'USER_USER_LOGIN_SUCCESS', payload: null });
			dispatch({
				type: 'USER_UPDATE_ACCESS_TOKEN',
				payload: null,
			});

			return store.doUpdateUrl({ pathname: '/login' });
			}
		});
    },
	doUserLogout:
		() =>
		({ dispatch, store }) => {
		dispatch({ type: 'USER_USER_LOGIN_SUCCESS', payload: null });
		dispatch({
			type: 'USER_UPDATE_ACCESS_TOKEN',
			payload: null,
		});
		return store.doUpdateUrl({ pathname: '/user-login' });
	},
	doLogout: () => ({ dispatch, store, core }) => {
		return core.doLogout().then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
				return res;
			} else {
				toast.success(res.message);

				dispatch({
					type: 'UPDATE_ACCESS_TOKEN',
				});
				return store.doUpdateUrl({ pathname: '/login' });
			}
		});
	},
};
