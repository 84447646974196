/* eslint-disable no-useless-escape */
import moment from 'moment';

import { dateFormat, TICKET_STATUS, timeFormat } from '@/constants';

import * as languages from '@/languages';

import { parse } from '@/utils/helpers';

export const validatePassword = string => true;
//  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(string);

export const validateEmail = email => {
	// var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// return re.test(String(email).toLowerCase());
	return true;
};

export const validateUsername = username => {
	// var re = /^(?=.{6,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/;
	// return re.test(String(username).toLowerCase());
	return true;
};

export const validatePasscode = (string) => /^[A-Za-z0-9]{4,}$/.test(string);

export function renderIconType(idDeleting, id, status) {
	if (idDeleting === id) {
		if (status === 'submitting') {
			return 'loading';
		}
		return status === null ? 'delete' : 'check';
	}
	return 'delete';
}

export function buildAq(s) {
	return 'aq=' + base64_encode(s);
}

export function base64_encode(s = '') {
	try {
		return btoa(s);
	} catch (e) {
		return false;
	}
}

export function base64_decode(s = '') {
	try {
		return atob(s);
	} catch (e) {
		return false;
	}
}

export function parseAq(s) {
	return parse(base64_decode(s.split('=')[1]));
}

export function formatNumber(number) {
	return new Intl.NumberFormat('en-US').format(parseInt(number) || 0);
}

export function convertDisplay(type = 'date', data, languageSelected = null) {
	let value;

	if (data !== undefined) {
		switch (type) {
			case 'date':
				if (data.toString().length === 10) {
					data = new Date(+data * 1000);
				}
				value = moment(data).format(dateFormat);
				break;
			case 'dateTime':
				value = moment(data).format(`${dateFormat} ${timeFormat}`);
				break;
			case 'ticketStatus': {
				switch (data) {
					case TICKET_STATUS.noStatus:
						value =
							languages[languageSelected].commons.eventTicketStatus.noStatus;
						break;
					case TICKET_STATUS.ticketAvailable:
						value =
							languages[languageSelected].commons.eventTicketStatus
								.ticketAvailable;
						break;
					case TICKET_STATUS.fewTicketsLeft:
						value =
							languages[languageSelected].commons.eventTicketStatus
								.fewTicketsLeft;
						break;
					case TICKET_STATUS.soldOut:
						value =
							languages[languageSelected].commons.eventTicketStatus.soldOut;
						break;
				}
				break;
			}
			case 'price': {
				value = `CHF ${(data || 0).toFixed(2)}`;
				break;
			}
			case 'time': {
				const h = Math.floor(data / 3600);
				const m = Math.floor((data % 3600) / 60);
				const s = data % 60;

				value = [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
					.filter(a => a)
					.join(':');

				break;
			}
			default:
				value = data;
		}
	}

	return value;
}
