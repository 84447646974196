import { toast } from 'react-toastify';

const bundle = {
	name: 'songBooks',
	doFetchSongBooks: params => ({ core }) => {
		return core.fetchSongBooks(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchSongBookDetail: id => ({ core }) => {
		return core.fetchSongBookDetail(id);
	},
	doAddAndUpdateSongBook: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddSongBook(params.data);
		}
		return core.doUpdateSongBook(params.data);
	},
	doDeleteSongBook: id => ({ core }) => {
		return core.doDeleteSongBook(id);
	},
};

export default bundle;
