import React from 'react';
import { Form, Input, Modal } from 'antd';

import { connect } from '@/lib/redux-bundler-react';

import * as languages from '@/languages';

const ChangePasswordModal = ({
	languageSelected,
	modalVisible,
	errors,
	isSubmiting,
	data,
	onModalOkClick = f => f,
	onModalCancelClick = f => f,
	onHandleChangeChangePasswordInfos = f => f,
}) => (
	<Modal
		title={languages[languageSelected].changePasswordModal.modalTitle}
		centered
		visible={modalVisible}
		onOk={() => onModalOkClick()}
		okText={
			isSubmiting
				? languages[languageSelected].commons.savingButton
				: languages[languageSelected].commons.saveButton
		}
		onCancel={() => onModalCancelClick(false)}
		cancelText={languages[languageSelected].commons.cancelButton}
	>
		<Form layout="vertical">
			<Form.Item
				label={
					languages[languageSelected].changePasswordModal.currentPasswordLabel
				}
				validateStatus={
					errors.currentPassword !== undefined
						? errors.currentPassword
							? 'error'
							: 'success'
						: null
				}
				help={
					errors.currentPassword !== undefined &&
					errors.currentPassword &&
					languages[languageSelected].changePasswordModal
						.currentPasswordValidateMessage
				}
				hasFeedback
			>
				<Input
					placeholder={
						languages[languageSelected].changePasswordModal
							.currentPasswordPlaceholder
					}
					disabled={isSubmiting}
					value={data.currentPassword}
					onChange={({ target }) =>
						onHandleChangeChangePasswordInfos({
							key: 'currentPassword',
							value: target.value,
						})
					}
				/>
			</Form.Item>
			<Form.Item
				label={languages[languageSelected].changePasswordModal.newPasswordLabel}
				validateStatus={
					errors.newPassword !== undefined
						? errors.newPassword
							? 'error'
							: 'success'
						: null
				}
				help={
					errors.newPassword !== undefined &&
					errors.newPassword &&
					languages[languageSelected].changePasswordModal
						.newPasswordValidateMessage
				}
				hasFeedback
			>
				<Input
					placeholder={
						languages[languageSelected].changePasswordModal
							.newPasswordPlaceholder
					}
					disabled={isSubmiting}
					value={data.newPassword}
					onChange={({ target }) =>
						onHandleChangeChangePasswordInfos({
							key: 'newPassword',
							value: target.value,
						})
					}
				/>
			</Form.Item>
			<Form.Item
				label={
					languages[languageSelected].changePasswordModal
						.confirmNewPasswordLabel
				}
				className="mb-0"
				validateStatus={
					errors.confirmNewPassword !== undefined
						? errors.confirmNewPassword
							? 'error'
							: 'success'
						: null
				}
				help={
					errors.confirmNewPassword !== undefined &&
					errors.confirmNewPassword &&
					languages[languageSelected].changePasswordModal
						.confirmNewPasswordValidateMessage
				}
				hasFeedback
			>
				<Input
					placeholder={
						languages[languageSelected].changePasswordModal
							.confirmNewPasswordPlaceholder
					}
					disabled={isSubmiting}
					value={data.confirmNewPassword}
					onChange={({ target }) =>
						onHandleChangeChangePasswordInfos({
							key: 'confirmNewPassword',
							value: target.value,
						})
					}
				/>
			</Form.Item>
		</Form>
	</Modal>
);

export default connect('selectLanguageSelected', ChangePasswordModal);
