import { toast } from 'react-toastify';

const bundle = {
	name: 'digitalContents',
	doFetchDigitalContents: params => ({ core }) => {
		return core.fetchDigitalContents(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},

	doAddAndUpdateDigitalContent: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddDigitalContent({ body: params.data });
		}
		return core.doUpdateDigitalContent({ body: params.data });
	},
};

export default bundle;
