import React from 'react';
import cn from 'classnames';

import {
	TopAppBar,
	TopAppBarRow,
	TopAppBarSection,
} from '@/components/mdc/TopAppBar';
import Icon from '@/components/Icon';

import { mainColorName } from '@/configs';

import { Connect } from '@/lib/redux-bundler-react';
import * as languages from '@/languages';

import styles from '@/App.module.scss';

const Header = () => (
	<TopAppBar
		fixed={false}
		className={`bg-${mainColorName}  relative ${styles['mdc-top-app-bar']}`}
	>
		<TopAppBarRow className={`bg-${mainColorName} h-full flex`}>
			<div className="flex justify-between w-full col">
				<TopAppBarSection
					alignStart
					className={styles['mdc-top-app-bar__section'] + ' p-0'}
				>
					<Connect to={['doToggleAppDrawer']}>
						{({ doToggleAppDrawer }) => (
							<button
								onClick={doToggleAppDrawer}
								className="rounded hover:bg-butterfly-bus bg-transparent focus:outline-none flex justify-center items-center w-10 h-10"
							>
								<Icon
									type="bars"
									className="rounded-none text-white flex text-2xl font-semibold"
								/>
							</button>
						)}
					</Connect>
				</TopAppBarSection>
				<TopAppBarSection
					alignEnd
					className={styles['mdc-top-app-bar__section'] + ' p-0'}
				>
					<Connect
						to={['doLogout', 'selectLanguageSelected', 'doChangeLanguage']}
					>
						{({ doLogout, languageSelected, doChangeLanguage }) => (
							<div className="flex items-center">
								<div className="language-icons flex items-center border border-green-light rounded py-1 mr-4 w-34 justify-center">
									<img
										src="/images/english.jpg"
										alt="English"
										title="English"
										className={cn(
											'w-auto h-8 cursor-pointer rounded shadow mr-2 border-3',
											languageSelected === 'english'
												? 'border-green-darker'
												: 'border-scampi',
										)}
										onClick={() =>
											languageSelected !== 'english' &&
											doChangeLanguage('english')
										}
									/>
									<img
										src="/images/germany.jpg"
										alt="Germany"
										title="Germany"
										className={cn(
											'w-auto h-8 cursor-pointer rounded shadow border-3',
											languageSelected === 'germany'
												? 'border-green-darker'
												: 'border-scampi',
										)}
										onClick={() =>
											languageSelected !== 'germany' &&
											doChangeLanguage('germany')
										}
									/>
								</div>
								<button
									onClick={() => doLogout()}
									className="w-32 rounded bg-butterfly-bus focus:outline-none flex justify-center items-center px-4 font-medium h-10"
								>
									<Icon type="logout" className="mr-1" />
									{languages[languageSelected].commons.logoutButton}
								</button>
							</div>
						)}
					</Connect>
				</TopAppBarSection>
			</div>
		</TopAppBarRow>
	</TopAppBar>
);

export default Header;
