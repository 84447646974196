const germany = {
	commons: {
		logoutButton: 'Ausloggen',
		userLoginStatus: 'Online',
		submiting: 'Bitte haben Sie einen Moment Geduld ...',
		loading: 'Daten werden geladen, bitte haben Sie einen Moment Geduld ...',
		searchInputPlaceholder: 'Geben Sie Stichwörter ein, um zu suchen ...',
		searchButton: 'Suche',
		userRoles: {
			all: 'Alle',
			admin: 'Administrator',
			parent: 'Elternteil',
			children: 'Kinder',
		},
		addButton: 'Hinzufügen',
		actionTableColumn: 'Aktionen',
		table: {
			perPage: 'pro Seite',
			items: 'Artikel',
			of: 'von',
			to: 'zu',
			item: 'Artikel',
		},
		saveButton: 'Sparen',
		savingButton: 'Speichern...',
		cancelButton: 'Stornieren',
		userGenders: {
			male: 'Männlich',
			feMale: 'Weiblich',
			notSpecified: 'Keine Angabe',
		},
		confirmYesButton: 'Ja',
		confirmNoButton: 'Nein',
		notFoundMessage:
			'Die von Ihnen gesuchte Seite ist leider nicht verfügbar. Bitte überprüfen Sie und versuchen Sie es jetzt!',
		eventTicketStatus: {
			noStatus: 'Kein Status',
			ticketAvailable: 'Ticket erhältlich',
			fewTicketsLeft: 'Nur noch wenige Tickets verfügbar',
			soldOut: 'Ausverkauft',
		},
		upload: 'Upload',
		uploading: 'Uploading...',
		clickToChange: 'Click to Change',
		clickToUpload: 'Click to Upload',
		submitingToServer: 'Submitting...',
		audioAlbumCategories: {
			musicAlbum: 'Musikalben',
			event: 'Themen',
			others: 'Weitere',
		},
		optionDefault: '[-- Select a value --]',
		somethingError: 'Oops, something went wrong!',
		bulkUploadButton: 'Bulk Upload',
		noFileSelected: 'No files selected',
	},
	// LOGIN PAGE
	loginScreen: {
		usernameLabel: 'Nutzername',
		usernameValidateMessage:
			'Benutzername hat mindestens 8 Zeichen und beginnt nicht mit Zahlen, _ oder.',
		usernamePlaceholder: 'Bitte geben Sie Ihren Benutzernamen ein',
		passwordLabel: 'Passwort',
		passwordValidateMessage:
			'Das Passwort hat mindestens 8 Zeichen mit 1 UPPER-CASE-Zeichen, 1 Kleinbuchstaben und 1 Ziffer.',
		passwordPlaceholder: 'Bitte geben Sie Ihr Passwort ein',
		forgotPassword: 'Passwort vergessen',
		loginButton: 'Einloggen',
	},
	// FORGOT PASSWORD MODAL
	forgotPassword: {
		forgotPasswordModalTitle: 'Passwort vergessen',
		okButton: 'Bestätigungscode senden',
		okButtonSubmiting: 'Senden...',
		cancelButton: 'Stornieren',
		emailLabel: 'Email',
		emailValidateMessage: 'Email Adresse ist nicht gültig!',
		emailPlaceholder: 'Geben Sie bitte Ihre Email-Adresse ein',
	},
	// RESET PASSWORD MODAL
	resetPassword: {
		resetPasswordModalTitle: 'Passwort zurücksetzen',
		okButton: 'Passwort zurücksetzen',
		okButtonSubmiting: 'Senden...',
		cancelButton: 'Stornieren',
		verificationCodeLabel: 'Bestätigungscode',
		verificationCodeValidateMessage: 'Bestätigungscode wird benötigt!',
		verificationCodePlaceholder: 'Bitte geben Sie Ihren Bestätigungscode ein',
		newPasswordLabel: 'Neues Passwort',
		newPasswordValidateMessage:
			'Das neue Passwort hat mindestens 8 Zeichen mit 1 UPPER-CASE-Zeichen, 1 Kleinbuchstaben und 1 Ziffer',
		newPasswordPlaceholder: 'Bitte geben Sie ein neues Passwort ein',
		confirmNewPasswordLabel: 'Bestätige neues Passwort',
		confirmNewPasswordValidateMessage:
			'Bestätige neues Passwort hat mindestens 8 Zeichen mit 1 UPPER-CASE-Zeichen, 1 Kleinbuchstaben und 1 Ziffer und muss mit dem neuen Passwort übereinstimmen',
		confirmNewPasswordPlaceholder:
			'Bitte geben Sie das neue Passwort erneut ein',
		forgotPasswordTokenValidateMessage: 'Passwort-Token nicht gefunden!',
	},
	dashboard: {
		dashboardPageTitle: 'Instrumententafel',
	},
	leftMenu: {
		manageUsers: 'Benutzer verwalten',
		users: 'Benutzer',
		digitalContents: 'Digitale Inhalte',
		music: 'Musik',
		audioBooks: 'Hörbücher',
		karaoke: 'Karaoke',
		video: 'Video',
		songBooks: 'Liederbücher',
		pictureBooks: 'Bilderbücher',
		inAppProducts: 'In-app Products',
		headlines: 'Headlines',
		manageEvents: 'Ereignisse verwalten',
		events: 'Veranstaltungen',
		notifications: 'Notifications',
		sentNotifications: 'Sent Notifications',
		scheduledNotifications: 'Scheduled Notifications',
		settings: 'die Einstellungen',
		systemSettings: 'Systemeinstellungen',
		changePassword: 'Passwort ändern',
	},
	userPage: {
		userPageTitle: 'Benutzer',
		fullname: 'Vollständiger Name',
		username: 'Nutzername',
		email: 'Email',
		role: 'Rolle',
		joinedDate: 'Beitrittsdatum',
		confirmMessage: 'Möchten Sie diesen Benutzer wirklich löschen?',
		notDeleteHimself:
			'Löschen des Benutzers fehlgeschlagen, konnte sich nicht selbst löschen',
	},
	addAndUpdateUserModal: {
		addUserTitle: 'Benutzer hinzufügen',
		updateUserTitle: 'Benutzer aktualisieren',
		firstNameLabel: 'Vorname',
		firstNameValidateMessage: 'Vorname ist erforderlich',
		firstNamePlaceholder: 'Bitte geben Sie Ihren Vornamen ein',
		lastNameLabel: 'Familienname, Nachname',
		lastNameValidateMessage: 'Nachname ist erforderlich',
		lastNamePlaceholder: 'Bitte geben Sie ihren Nachnamen ein',
		userNameLabel: 'Nutzername',
		userNameValidateMessage:
			'Benutzername hat mindestens 8 Zeichen und beginnt nicht mit Zahlen, _ oder. ',
		userNamePlaceholder: 'Bitte geben Sie Ihren Benutzernamen ein',
		emailLabel: 'Email',
		emailValidateMessage: 'Email Adresse ist nicht gültig',
		emailPlaceholder: 'Bitte geben Sie ihre E-Mail-Adresse ein',
		passwordLabel: 'Passwort',
		passwordValidateMessage:
			'Das Passwort hat mindestens 8 Zeichen mit 1 UPPER-CASE-Zeichen, 1 Kleinbuchstaben und 1 Ziffer',
		passwordPlaceholder: 'Bitte geben Sie Ihr Passwort ein',
		confirmPasswordLabel: 'Passwort bestätigen',
		confirmPasswordValidateMessage:
			'Passwort bestätigen hat mindestens 8 Zeichen mit 1 GROSSBUCHSTABEN, 1 Kleinbuchstaben und 1 Ziffer und muss mit dem Passwort übereinstimmen',
		confirmPasswordPlaceholder: 'Bitte geben Sie Ihr Passwort ein',
		typeLabel: 'Art',
		typeValidateMessage: 'Typ ist erforderlich',
		typePlaceholder: 'Bitte wählen Sie einen Wert',
		dateOfBirthLabel: 'Geburtsdatum',
		dateOfBirthPlaceholder: 'Bitte wählen Sie Ihr Geburtsdatum',
		genderLabel: 'Geschlecht',
		genderPlaceholder: 'Bitte wählen Sie einen Wert',
		phoneNumberLabel: 'Telefonnummer',
		phoneNumberPlaceholder: 'Bitte geben sie ihre Telefonnummer ein',
		enableSharing: 'Freigabe aktivieren',
	},
	userDetails: {
		userDetailPageTitle: 'Nutzerdetails',
		firstName: 'Vorname',
		lastName: 'Familienname, Nachname',
		username: 'Nutzername',
		email: 'Email',
		type: 'Art',
		joinedDate: 'Beitrittsdatum',
		dateOfBirth: 'Geburtsdatum',
		gender: 'Geschlecht',
		phoneNumber: 'Telefonnummer',
		status: 'Status',
		enableSharing: 'Freigabe aktivieren',
		enableSharingYes: 'Ja',
		enableSharingNo: 'Nein',
		rewardPoints: 'Belohnungspunkte',
	},
	events: {
		categoriesTitle: 'Kategorien',
		deleteEventCategoryConfirmMessage:
			'Möchten Sie diese Ereigniskategorie wirklich löschen?',
		eventsTitle: 'Veranstaltungen',
		eventImagesTitle: 'Event Images',
		name: 'Name',
		location: 'Lage',
		startTime: 'Event Time',
		endTime: 'Endzeit',
		deleteEventConfirmMessage: 'Möchten Sie diesen Termin wirklich löschen?',
		deleteEventImageConfirmMessage:
			'Do you really want to delete this event image?',
	},
	addAndEditEventCategoryModal: {
		addEventCategoryTitle: 'Ereigniskategorie hinzufügen',
		updateEventCategoryTitle: 'Ereigniskategorie aktualisieren ',
		nameLabel: 'Name',
		nameValidateMessage: 'Name ist erforderlich!',
		namePlaceholder: 'Bitte geben Sie Ihren Kategorienamen ein',
	},
	addAndEditEventModal: {
		addEventTitle: 'Ereignis hinzufügen',
		updateEventTitle: 'Ereignis aktualisieren',
		categoryLabel: 'Kategorie',
		categoryValidateMessage: 'Kategorie ist erforderlich!',
		categoryPlaceholder: 'Bitte wählen Sie einen Wert',
		nameLabel: 'Name',
		nameValidateMessage: 'Name ist erforderlich!',
		namePlaceholder: 'Bitte geben Sie Ihren Veranstaltungsnamen ein',
		locationLabel: 'Lage',
		locationValidateMessage: 'Standort ist erforderlich!',
		locationPlaceholder: 'Bitte geben Sie Ihren Veranstaltungsort an',
		startTimeLabel: 'Startzeit',
		startTimeValidateMessage: 'Startzeit ist erforderlich!',
		startTimePlaceholder: 'Bitte geben Sie Ihre Veranstaltungsstartzeit ein',
		endTimeLabel: 'Endzeit',
		endTimeValidateMessage: 'Endzeit ist erforderlich!',
		endTimePlaceholder: 'Bitte geben Sie Ihre Event-Endzeit ein',
		urlLabel: 'Url',
		urlPlaceholder: 'Bitte geben Sie Ihre Event-URL ein',
		descriptionLabel: 'Beschreibung',
		descriptionPlaceholder: 'Bitte geben Sie Ihre Eventbeschreibung ein',
		statusLabel: 'Status',
		statusValidateMessage: 'Status is required!',
	},
	eventDetails: {
		eventDetailTitle: 'Veranstaltungsdetails',
		category: 'Kategorie',
		name: 'Name',
		location: 'Lage',
		startTime: 'Startzeit',
		endTime: 'Endzeit',
		url: 'Url',
		description: 'Beschreibung',
		eventIdNotFound: 'Event ID nicht gefunden!',
		status: 'Status',
	},
	eventTickets: {
		eventTicketsTitle: 'Event-Tickets',
		name: 'Name',
		price: 'Preis',
		status: 'Status',
		deleteEventTicketConfirmMessage:
			'Möchten Sie dieses Veranstaltungsticket wirklich löschen?',
	},
	addAndEditEventTicketModal: {
		addEventTicketModalTitle: 'Veranstaltungsticket hinzufügen',
		updateEventTicketModalTitle: 'Veranstaltungsticket aktualisieren',
		nameLabel: 'Name',
		nameValidateMessage: 'Name ist erforderlich!',
		namePlaceholder: 'Bitte geben Sie Ihren Event Ticket Namen ein',
		priceLabel: 'Preis',
		priceValidateMessage: 'Preis ist erforderlich!',
		pricePlaceholder:
			'Bitte geben Sie Ihren Eintrittspreis für die Veranstaltung ein',
	},
	changePasswordModal: {
		modalTitle: 'Passwort ändern',
		currentPasswordLabel: 'Aktuelles Passwort',
		currentPasswordValidateMessage:
			'Das aktuelle Passwort besteht aus mindestens 8 Zeichen mit 1 GROSSBUCHSTABEN, 1 Kleinbuchstaben und 1 Ziffer',
		currentPasswordPlaceholder: 'Bitte geben Sie ein aktuelles Passwort ein',
		newPasswordLabel: 'Neues Passwort',
		newPasswordValidateMessage:
			'Das neue Passwort hat mindestens 8 Zeichen mit 1 UPPER-CASE-Zeichen, 1 Kleinbuchstaben und 1 Ziffer',
		newPasswordPlaceholder: 'Bitte geben Sie ein neues Passwort ein',
		confirmNewPasswordLabel: 'Bestätige neues Passwort',
		confirmNewPasswordValidateMessage:
			'Bestätige neues Passwort hat mindestens 8 Zeichen mit 1 UPPER-CASE-Zeichen, 1 Kleinbuchstaben und 1 Ziffer und muss mit dem neuen Passwort übereinstimmen',
		confirmNewPasswordPlaceholder:
			'Bitte geben Sie das neue Passwort erneut ein',
	},
	musicAlbums: {
		musicAlbumsTitle: 'Musikalben',
		coverPicture: 'Titelbild',
		name: 'Name',
		price: 'Preis',
		deleteMusicAlbumConfirmMessage:
			'Do you really want to delete this music album?',
	},
	addAndEditMusicAlbumModal: {
		addMusicAlbumModalTitle: 'Musikalbum hinzufügen',
		updateMusicAlbumModalTitle: 'Musikalbum aktualisieren',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your music album name',
		categoryLabel: 'Category',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your music album price',
		bookletLabel: 'Booklet',
		bookletValidateMessage: 'Booklet is required',
	},
	musicAlbumDetails: {
		musicAlbumIdNotFound: 'Music album id not found!',
		musicAlbumDetailTitle: 'Music Album Details',
		name: 'Name',
		category: 'Category',
		coverPicture: 'Cover Picture',
		booklet: 'Booklet',
		price: 'Price',
		fullVersionSongsTabTitle: 'Full Version Songs',
		karaokeVersionSongsTabTitle: 'Karaoke Version Songs',
		previewSongsTabTitle: 'Preview Songs',
		audioTable: {
			track: 'Track',
			name: 'Name',
			duration: 'Duration',
			action: 'Action',
			free: 'Free Version',
			purchasableIndividually: 'Purchasable Individually',
			price: 'Price',
		},
		deleteAudioFileConfirmMessage:
			'Do you really want to delete this audio file?',
	},
	addMusicFileModal: {
		addMusicFileModalTitle: 'Add Music Song',
		updateMusicFileModalTitle: 'Update Music Song',
		addAudioBookFileModalTitle: 'Add Audio Book',
		updateAudioBookFileModalTitle: 'Update Audio Book',
		fileLabel: 'File',
		fileValidateMessage: 'File is required',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		freeVersionLabel: 'Free Version',
		freeVersionValidateMessage: 'Free version is required',
		purchasableIndividuallyLabel: 'Purchasable individually',
		purchasableIndividuallyValidateMessage:
			'Purchasable individually is required',
	},
	audioBooks: {
		audioBooksTitle: 'Audio Book Albums',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deleteAudioBookConfirmMessage:
			'Do you really want to delete this audio book album?',
	},
	addAndEditAudioBookModal: {
		addAudioBookModalTitle: 'Add Audio Book Album',
		updateAudioBookModalTitle: 'Update Audio Book Album',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your audio book album name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your audio book album price',
		bookletLabel: 'Booklet',
		bookletValidateMessage: 'Booklet is required',
	},
	audioBookDetails: {
		audioBookIdNotFound: 'Audio book id not found!',
		audioBookDetailTitle: 'Audio Book Album Details',
		name: 'Name',
		coverPicture: 'Cover Picture',
		booklet: 'Booklet',
		price: 'Price',
		radioPlaysTabTitle: 'Radio Plays',
		songsTabTitle: 'Songs',
		previewSongsTabTitle: 'Preview Songs',
		audioTable: {
			track: 'Track',
			name: 'Name',
			duration: 'Duration',
			action: 'Action',
		},
		deleteAudioFileConfirmMessage:
			'Do you really want to delete this audio file?',
	},
	karaokeSongs: {
		karaokeSongsTitle: 'Karaoke Songs',
		name: 'Name',
		price: 'Price',
		free: 'Free',
		files: 'Files',
		deleteKaraokeSongConfirmMessage:
			'Do you really want to delete this karaoke song?',
	},
	addAndEditKaraokeSongModal: {
		addKaraokeSongModalTitle: 'Add Karaoke Song',
		updateKaraokeSongModalTitle: 'Update Karaoke Song',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your karaoke song name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your karaoke song price',
		originalFileLabel: 'Original File',
		originalFileValidateMessage: 'Original file is required',
		karaokeFileLabel: 'Karaoke File',
		lyricsFileLabel: 'Lyrics File',
		freeVersionLabel: 'Free',
	},
	videoAlbums: {
		videoAlbumsTitle: 'Video Albums',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deleteVideoAlbumConfirmMessage:
			'Do you really want to delete this video album?',
	},
	addAndEditVideoAlbumModal: {
		addVideoAlbumModalTitle: 'Add Video Album',
		updateVideoAlbumModalTitle: 'Update Video Album',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your video album name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your video album price',
		bookletLabel: 'Booklet',
		bookletValidateMessage: 'Booklet is required',
	},
	videoAlbumDetails: {
		videoAlbumIdNotFound: 'Video album id not found!',
		videoAlbumDetailTitle: 'Video Album Details',
		name: 'Name',
		coverPicture: 'Cover Picture',
		price: 'Price',
		introductionTabTitle: 'Introduction',
		fullVideosSDTabTitle: 'Full Videos SD',
		fullVideosHDTabTitle: 'Full Videos HD',
		instructionVideosSDTabTitle: 'Instruction Videos SD',
		instructionVideosHDTabTitle: 'Instruction Videos HD',
		endingTabTitle: 'Ending',
		videoTable: {
			track: 'Track',
			name: 'Name',
			duration: 'Duration',
			action: 'Action',
		},
		deleteVideoFileConfirmMessage:
			'Do you really want to delete this video file?',
	},
	addVideoFileModal: {
		addVideoFileModalTitle: 'Add Video File',
		updateVideoFileModalTitle: 'Update Video File',
		fileLabel: 'File',
		fileValidateMessage: 'File is required',
		freeLabel: 'Free',
	},
	songBooks: {
		songBooksTitle: 'Song Books',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deleteSongBookConfirmMessage:
			'Do you really want to delete this song book?',
	},
	addAndEditSongBookModal: {
		addSongBookModalTitle: 'Add Song Book',
		updateSongBookModalTitle: 'Update Song Book',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your song book name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your song book price',
		bookFileLabel: 'Book File',
		bookFileValidateMessage: 'Book file is required',
		excelFileLabel: 'Split File',
		excelFileValidateMessage: 'Split file is required',
	},
	songBookDetails: {
		songBookDetailTitle: 'Song Book Details',
		name: 'Name',
		coverPicture: 'Cover Picture',
		price: 'Price',
		vocalWithInstrumentTabTitle: 'Vocal With Instrument',
		instrumentOnlyTabTitle: 'Instrument Only',
		songTable: {
			track: 'Track',
			name: 'Name',
		},
	},
	pictureBooks: {
		pictureBooksTitle: 'Picture Books',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deletePictureBookConfirmMessage:
			'Do you really want to delete this picture book?',
	},
	addAndEditPictureBookModal: {
		addPictureBookModalTitle: 'Add Picture Book',
		updatePictureBookModalTitle: 'Update Picture Book',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your picture book name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your picture book price',
		bookFileLabel: 'Book File',
		bookFileValidateMessage: 'Book file is required',
		excelFileLabel: 'Split File',
		excelFileValidateMessage: 'Split file is required',
	},
	pictureBookDetails: {
		pictureBookDetailTitle: 'Picture Book Details',
		name: 'Name',
		price: 'Price',
		vocalWithInstrumentTabTitle: 'Vocal With Instrument',
		instrumentOnlyTabTitle: 'Instrument Only',
		pictureTable: {
			track: 'Track',
			name: 'Name',
		},
	},
	notifications: {
		sentNotificationsTitle: 'Sent Notifications',
		sentTime: 'Sent Time',
		title: 'Title',
		message: 'Message',
		recipients: 'Recipients',
		broadcastNewNotification: 'Broadcast New Notification',
		scheduledNotificationsTitle: 'Scheduled Notifications',
		scheduledTime: 'Scheduled Time',
		createdTime: 'Created Time',
		scheduleNewNotification: 'Schedule New Notification',
		deletesCheduledNotificationConfirmMessage:
			'Do you really want to delete this notification?',
	},
	broadcastNewNotificationModal: {
		broadcastNewNotificationModalTitle: 'Broadcast New Notification',
		titleLabel: 'Title',
		titleValidateMessage: 'Title is required',
		titlePlaceholder: 'Please enter your notification title',
		messageLabel: 'Message',
		messageValidateMessage: 'Message is required',
		messagePlaceholder: 'Please enter your notification message',
		recLabel: 'Rectangle Photo',
		squareLabel: 'Square Photo',
		broadcast: 'Broadcast',
		schedule: 'Schedule',
		scheduleNewNotification: 'Schedule New Notification',
		scheduleDateLabel: 'Schedule Date',
		scheduleDateValidateMessage: 'Schedule date is required',
		scheduleDatePlaceholder: 'Please select your schedule notification date',
		scheduleTimeLabel: 'Schedule Time',
		scheduleTimeValidateMessage: 'Schedule time is required',
		scheduleTimePlaceholder: 'Please select your schedule notification time',
		recipients: 'Recipients',
		allUsers: 'All users',
		specificUsers: 'Specific users',
		emailPlaceholder: 'Please enter list emails',
		emailNote: 'Each email address is separated by comma',
		emailValidateMessage: 'Email is required',
	},
	inAppProducts: {
		inAppProductsTitle: 'In-app Products',
		name: 'Name',
		productId: 'Product Id',
		price: 'Price',
		deletesInAppProductConfirmMessage:
			'Do you really want to delete this product?',
	},
	addAndEditInAppProductModal: {
		addInAppProductModalTitle: 'Add In-app Product',
		updateInAppProductModalTitle: 'Update In-app Product',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your product name',
		productIdLabel: 'Product Id',
		productIdValidateMessage: 'Product Id is required',
		productIdPlaceholder: 'Please enter your product id',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your product price',
	},
	headlines: {
		headlinesTitle: 'Headlines',
		type: 'Type',
		headline: 'Headline',
	},
	addAndEditHeadLineModal: {
		addHeadLineModalTitle: 'Add Headline',
		updateHeadLineModalTitle: 'Update Headline',
		type: 'Type',
		headlineLabel: 'Headline',
		headlineValidateMessage: 'Headline name is required',
		headlinePlaceholder: 'Please enter your headline name',
	},
	audioBooksBulkUploadModal: {
		modalTitle: 'Bulk Upload',
	},
};

export default germany;
