const english = {
	commons: {
		logoutButton: 'Logout',
		userLoginStatus: 'Online',
		submiting: 'In progress, please wait a moment ...',
		loading: 'Loading data, please wait a moment ...',
		searchInputPlaceholder: 'Enter keywords to search...',
		searchButton: 'Search',
		userRoles: {
			all: 'All',
			admin: 'Admin',
			parent: 'Parent',
			children: 'Children',
		},
		addButton: 'Add',
		actionTableColumn: 'Actions',
		table: {
			perPage: 'per page',
			items: 'items',
			of: 'of',
			to: 'to',
			item: 'Item',
		},
		saveButton: 'Save',
		savingButton: 'Saving...',
		cancelButton: 'Cancel',
		userGenders: {
			male: 'Male',
			feMale: 'Female',
			notSpecified: 'Not Specified',
		},
		confirmYesButton: 'Yes',
		confirmNoButton: 'No',
		notFoundMessage:
			'Sorry, the page you were looking for is not available. Please check and try now!',
		eventTicketStatus: {
			noStatus: 'No Status',
			ticketAvailable: 'Ticket Available',
			fewTicketsLeft: 'Few Tickets Left',
			soldOut: 'Sold Out',
		},
		upload: 'Upload',
		uploading: 'Uploading...',
		clickToChange: 'Click to Change',
		clickToUpload: 'Click to Upload',
		submitingToServer: 'Submitting...',
		audioAlbumCategories: {
			musicAlbum: 'Music Album',
			event: 'Event',
			others: 'Others',
		},
		somethingError: 'Oops, something went wrong!',
		bulkUploadButton: 'Bulk Upload',
		noFileSelected: 'No files selected',
	},
	// LOGIN PAGE
	loginScreen: {
		usernameLabel: 'Username',
		usernameValidateMessage:
			'Username has at least 8 characters, not starting with numbers, _ or .',
		usernamePlaceholder: 'Please enter your username',
		passwordLabel: 'Password',
		passwordValidateMessage:
			'Password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number.',
		passwordPlaceholder: 'Please enter your password',
		forgotPassword: 'Forgot password',
		loginButton: 'Login',
	},
	// FORGOT PASSWORD MODAL
	forgotPassword: {
		forgotPasswordModalTitle: 'Forgot password',
		okButton: 'Send Verification Code',
		okButtonSubmiting: 'Sending...',
		cancelButton: 'Cancel',
		emailLabel: 'Email',
		emailValidateMessage: 'Email address is not valid!',
		emailPlaceholder: 'Please enter your email address',
	},
	// RESET PASSWORD MODAL
	resetPassword: {
		resetPasswordModalTitle: 'Reset Password',
		okButton: 'Reset Password',
		okButtonSubmiting: 'Sending...',
		cancelButton: 'Cancel',
		verificationCodeLabel: 'Verification Code',
		verificationCodeValidateMessage: 'Verification code is required!',
		verificationCodePlaceholder: 'Please enter your verification code',
		newPasswordLabel: 'New Password',
		newPasswordValidateMessage:
			'New password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number',
		newPasswordPlaceholder: 'Please enter a new password',
		confirmNewPasswordLabel: 'Confirm New Password',
		confirmNewPasswordValidateMessage:
			'Confirm new password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number and must be the same as the new password',
		confirmNewPasswordPlaceholder: 'Please retype the new password',
		forgotPasswordTokenValidateMessage: 'Forgot password token not found!',
	},
	// DASHBORAD
	dashboard: {
		dashboardPageTitle: 'Dashboard',
	},
	leftMenu: {
		manageUsers: 'Manage Users',
		users: 'Users',
		digitalContents: 'Digital Contents',
		music: 'Music',
		audioBooks: 'Audio Books',
		karaoke: 'Karaoke',
		video: 'Video',
		songBooks: 'Song Books',
		pictureBooks: 'Picture Books',
		inAppProducts: 'In-app Products',
		headlines: 'Headlines',
		manageEvents: 'Manage Events',
		events: 'Events',
		notifications: 'Notifications',
		sentNotifications: 'Sent Notifications',
		scheduledNotifications: 'Scheduled Notifications',
		settings: 'Settings',
		systemSettings: 'System Settings',
		changePassword: 'Change Password',
	},
	userPage: {
		userPageTitle: 'Users',
		fullname: 'Full Name',
		username: 'Username',
		email: 'Email',
		role: 'Role',
		joinedDate: 'Joined Date',
		confirmMessage: 'Do you really want to delete this user?',
		notDeleteHimself: 'Delete user failed, could not delete himself',
	},
	addAndUpdateUserModal: {
		addUserTitle: 'Add User',
		updateUserTitle: 'Update User',
		firstNameLabel: 'First Name',
		firstNameValidateMessage: 'First name is required',
		firstNamePlaceholder: 'Please enter your first name',
		lastNameLabel: 'Last Name',
		lastNameValidateMessage: 'Last name is required',
		lastNamePlaceholder: 'Please enter your last name',
		userNameLabel: 'Username',
		userNameValidateMessage:
			'Username has at least 8 characters, not starting with numbers, _ or .',
		userNamePlaceholder: 'Please enter your username',
		emailLabel: 'Email',
		emailValidateMessage: 'Email address is not valid',
		emailPlaceholder: 'Please enter your email',
		passwordLabel: 'Password',
		passwordValidateMessage:
			'Password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number',
		passwordPlaceholder: 'Please enter your password',
		confirmPasswordLabel: 'Confirm Password',
		confirmPasswordValidateMessage:
			'Confirm password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number and must be the same as the password',
		confirmPasswordPlaceholder: 'Please enter your confirm password',
		typeLabel: 'Type',
		typeValidateMessage: 'Type is required',
		typePlaceholder: 'Please select a value',
		dateOfBirthLabel: 'Date of Birth',
		dateOfBirthPlaceholder: 'Please select your date of birth',
		genderLabel: 'Gender',
		genderPlaceholder: 'Please select a value',
		phoneNumberLabel: 'Phone Number',
		phoneNumberPlaceholder: 'Please enter your phone number',
		enableSharing: 'Enable Sharing',
	},
	userDetails: {
		userDetailPageTitle: 'User Details',
		firstName: 'First Name',
		lastName: 'Last Name',
		username: 'Username',
		email: 'Email',
		type: 'Type',
		joinedDate: 'Joined Date',
		dateOfBirth: 'Date of Birth',
		gender: 'Gender',
		phoneNumber: 'Phone Number',
		status: 'Status',
		enableSharing: 'Enable Sharing',
		enableSharingYes: 'Yes',
		enableSharingNo: 'No',
		rewardPoints: 'Reward Points',
	},
	events: {
		categoriesTitle: 'Categories',
		deleteEventCategoryConfirmMessage:
			'Do you really want to delete this event category?',
		eventsTitle: 'Events',
		eventImagesTitle: 'Event Images',
		name: 'Name',
		location: 'Location',
		startTime: 'Event Time',
		endTime: 'End Time',
		deleteEventConfirmMessage: 'Do you really want to delete this event?',
		deleteEventImageConfirmMessage:
			'Do you really want to delete this event image?',
	},
	addAndEditEventCategoryModal: {
		addEventCategoryTitle: 'Add Event Category',
		updateEventCategoryTitle: 'Add Event Category',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required!',
		namePlaceholder: 'Please enter your category name',
	},
	addAndEditEventModal: {
		addEventTitle: 'Add Event',
		updateEventTitle: 'Update Event',
		categoryLabel: 'Category',
		categoryValidateMessage: 'Category is required!',
		categoryPlaceholder: 'Please select a value',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required!',
		namePlaceholder: 'Please enter your event name',
		locationLabel: 'Location',
		locationValidateMessage: 'Location is required!',
		locationPlaceholder: 'Please enter your event location',
		startTimeLabel: 'Start Time',
		startTimeValidateMessage: 'Start time is required!',
		startTimePlaceholder: 'Please enter your event start time',
		endTimeLabel: 'End Time',
		endTimeValidateMessage: 'End time is required!',
		endTimePlaceholder: 'Please enter your event end time',
		urlLabel: 'Url',
		urlPlaceholder: 'Please enter your event url',
		descriptionLabel: 'Description',
		descriptionPlaceholder: 'Please enter your event description',
		statusLabel: 'Status',
		statusValidateMessage: 'Status is required!',
	},
	eventDetails: {
		eventDetailTitle: 'Event Details',
		category: 'Category',
		name: 'Name',
		location: 'Location',
		startTime: 'Start Time',
		endTime: 'End Time',
		url: 'Url',
		description: 'Description',
		eventIdNotFound: 'Event id not found!',
		status: 'Status',
	},
	eventTickets: {
		eventTicketsTitle: 'Event Tickets',
		name: 'Name',
		price: 'Price',
		status: 'Status',
		deleteEventTicketConfirmMessage:
			'Do you really want to delete this event ticket?',
	},
	addAndEditEventTicketModal: {
		addEventTicketModalTitle: 'Add Event Ticket',
		updateEventTicketModalTitle: 'Update Event Ticket',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required!',
		namePlaceholder: 'Please enter your event ticket name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required!',
		pricePlaceholder: 'Please enter your event ticket price',
	},
	changePasswordModal: {
		modalTitle: 'Change Password',
		currentPasswordLabel: 'Current Password',
		currentPasswordValidateMessage:
			'Current password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number',
		currentPasswordPlaceholder: 'Please enter a current password',
		newPasswordLabel: 'New Password',
		newPasswordValidateMessage:
			'New password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number',
		newPasswordPlaceholder: 'Please enter a new password',
		confirmNewPasswordLabel: 'Confirm New Password',
		confirmNewPasswordValidateMessage:
			'Confirm new password has at least 8 character with 1 UPPER-CASE character, 1 lower-case character and 1 number and must be the same as the new password',
		confirmNewPasswordPlaceholder: 'Please retype the new password',
	},
	musicAlbums: {
		musicAlbumsTitle: 'Music Albums',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deleteMusicAlbumConfirmMessage:
			'Do you really want to delete this music album?',
	},
	addAndEditMusicAlbumModal: {
		addMusicAlbumModalTitle: 'Add Music Album',
		updateMusicAlbumModalTitle: 'Update Music Album',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your music album name',
		categoryLabel: 'Category',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your music album price',
		bookletLabel: 'Booklet',
		bookletValidateMessage: 'Booklet is required',
	},
	musicAlbumDetails: {
		musicAlbumIdNotFound: 'Music album id not found!',
		musicAlbumDetailTitle: 'Music Album Details',
		name: 'Name',
		category: 'Category',
		coverPicture: 'Cover Picture',
		booklet: 'Booklet',
		price: 'Price',
		fullVersionSongsTabTitle: 'Full Version Songs',
		karaokeVersionSongsTabTitle: 'Karaoke Version Songs',
		previewSongsTabTitle: 'Preview Songs',
		audioTable: {
			track: 'Track',
			name: 'Name',
			duration: 'Duration',
			action: 'Action',
			free: 'Free Version',
			purchasableIndividually: 'Purchasable Individually',
			price: 'Price',
		},
		deleteAudioFileConfirmMessage:
			'Do you really want to delete this audio file?',
	},
	addMusicFileModal: {
		addMusicFileModalTitle: 'Add Music Song',
		updateMusicFileModalTitle: 'Update Music Song',
		addAudioBookFileModalTitle: 'Add Audio Book',
		updateAudioBookFileModalTitle: 'Update Audio Book',
		fileLabel: 'File',
		fileValidateMessage: 'File is required',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		freeVersionLabel: 'Free Version',
		freeVersionValidateMessage: 'Free version is required',
		purchasableIndividuallyLabel: 'Purchasable individually',
		purchasableIndividuallyValidateMessage:
			'Purchasable individually is required',
	},
	audioBooks: {
		audioBooksTitle: 'Audio Book Albums',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deleteAudioBookConfirmMessage:
			'Do you really want to delete this audio book album?',
	},
	addAndEditAudioBookModal: {
		addAudioBookModalTitle: 'Add Audio Book Album',
		updateAudioBookModalTitle: 'Update Audio Book Album',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your audio book album name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your audio book album price',
		bookletLabel: 'Booklet',
		bookletValidateMessage: 'Booklet is required',
	},
	audioBookDetails: {
		audioBookIdNotFound: 'Audio book id not found!',
		audioBookDetailTitle: 'Audio Book Album Details',
		name: 'Name',
		coverPicture: 'Cover Picture',
		booklet: 'Booklet',
		price: 'Price',
		radioPlaysTabTitle: 'Radio Plays',
		songsTabTitle: 'Songs',
		previewSongsTabTitle: 'Preview Songs',
		audioTable: {
			track: 'Track',
			name: 'Name',
			duration: 'Duration',
			action: 'Action',
		},
		deleteAudioFileConfirmMessage:
			'Do you really want to delete this audio file?',
	},
	karaokeSongs: {
		karaokeSongsTitle: 'Karaoke Songs',
		name: 'Name',
		price: 'Price',
		free: 'Free',
		files: 'Files',
		deleteKaraokeSongConfirmMessage:
			'Do you really want to delete this karaoke song?',
	},
	addAndEditKaraokeSongModal: {
		addKaraokeSongModalTitle: 'Add Karaoke Song',
		updateKaraokeSongModalTitle: 'Update Karaoke Song',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your karaoke song name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your karaoke song price',
		originalFileLabel: 'Original File',
		originalFileValidateMessage: 'Original file is required',
		karaokeFileLabel: 'Karaoke File',
		lyricsFileLabel: 'Lyrics File',
		freeVersionLabel: 'Free',
	},
	videoAlbums: {
		videoAlbumsTitle: 'Video Albums',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deleteVideoAlbumConfirmMessage:
			'Do you really want to delete this video album?',
	},
	addAndEditVideoAlbumModal: {
		addVideoAlbumModalTitle: 'Add Video Album',
		updateVideoAlbumModalTitle: 'Update Video Album',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your video album name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your video album price',
		bookletLabel: 'Booklet',
		bookletValidateMessage: 'Booklet is required',
	},
	videoAlbumDetails: {
		videoAlbumIdNotFound: 'Video album id not found!',
		videoAlbumDetailTitle: 'Video Album Details',
		name: 'Name',
		coverPicture: 'Cover Picture',
		price: 'Price',
		introductionTabTitle: 'Introduction',
		fullVideosSDTabTitle: 'Full Videos SD',
		fullVideosHDTabTitle: 'Full Videos HD',
		instructionVideosSDTabTitle: 'Instruction Videos SD',
		instructionVideosHDTabTitle: 'Instruction Videos HD',
		endingTabTitle: 'Ending',
		videoTable: {
			track: 'Track',
			name: 'Name',
			duration: 'Duration',
			action: 'Action',
		},
		deleteVideoFileConfirmMessage:
			'Do you really want to delete this video file?',
	},
	addVideoFileModal: {
		addVideoFileModalTitle: 'Add Video File',
		updateVideoFileModalTitle: 'Update Video File',
		fileLabel: 'File',
		fileValidateMessage: 'File is required',
		freeLabel: 'Free',
	},
	songBooks: {
		songBooksTitle: 'Song Books',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deleteSongBookConfirmMessage:
			'Do you really want to delete this song book?',
	},
	addAndEditSongBookModal: {
		addSongBookModalTitle: 'Add Song Book',
		updateSongBookModalTitle: 'Update Song Book',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your song book name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your song book price',
		bookFileLabel: 'Book File',
		bookFileValidateMessage: 'Book file is required',
		excelFileLabel: 'Split File',
		excelFileValidateMessage: 'Split file is required',
	},
	songBookDetails: {
		songBookDetailTitle: 'Song Book Details',
		name: 'Name',
		price: 'Price',
		vocalWithInstrumentTabTitle: 'Vocal With Instrument',
		instrumentOnlyTabTitle: 'Instrument Only',
		songTable: {
			track: 'Track',
			name: 'Name',
		},
	},
	pictureBooks: {
		pictureBooksTitle: 'Picture Books',
		coverPicture: 'Cover Picture',
		name: 'Name',
		price: 'Price',
		deletePictureBookConfirmMessage:
			'Do you really want to delete this picture book?',
	},
	addAndEditPictureBookModal: {
		addPictureBookModalTitle: 'Add Picture Book',
		updatePictureBookModalTitle: 'Update Picture Book',
		coverPictureLabel: 'Cover Picture',
		coverPictureValidateMessage: 'Cover picture is required',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your picture book name',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your picture book price',
		bookFileLabel: 'Book File',
		bookFileValidateMessage: 'Book file is required',
		excelFileLabel: 'Split File',
		excelFileValidateMessage: 'Split file is required',
	},
	pictureBookDetails: {
		pictureBookDetailTitle: 'Picture Book Details',
		name: 'Name',
		price: 'Price',
		vocalWithInstrumentTabTitle: 'Vocal With Instrument',
		instrumentOnlyTabTitle: 'Instrument Only',
		pictureTable: {
			track: 'Track',
			name: 'Name',
		},
	},
	notifications: {
		sentNotificationsTitle: 'Sent Notifications',
		sentTime: 'Sent Time',
		title: 'Title',
		message: 'Message',
		recipients: 'Recipients',
		broadcastNewNotification: 'Broadcast New Notification',
		scheduledNotificationsTitle: 'Scheduled Notifications',
		scheduledTime: 'Scheduled Time',
		createdTime: 'Created Time',
		scheduleNewNotification: 'Schedule New Notification',
		deletesCheduledNotificationConfirmMessage:
			'Do you really want to delete this notification?',
	},
	broadcastNewNotificationModal: {
		broadcastNewNotificationModalTitle: 'Broadcast New Notification',
		titleLabel: 'Title',
		titleValidateMessage: 'Title is required',
		titlePlaceholder: 'Please enter your notification title',
		messageLabel: 'Message',
		messageValidateMessage: 'Message is required',
		messagePlaceholder: 'Please enter your notification message',
		recLabel: 'Rectangle Photo',
		squareLabel: 'Square Photo',
		broadcast: 'Broadcast',
		schedule: 'Schedule',
		scheduleNewNotification: 'Schedule New Notification',
		scheduleDateLabel: 'Schedule Date',
		scheduleDateValidateMessage:
			'Schedule date is required and must be in the feature',
		scheduleDatePlaceholder: 'Please select your schedule notification date',
		scheduleTimeLabel: 'Schedule Time',
		scheduleTimeValidateMessage:
			'Schedule time is required and must be in the feature',
		scheduleTimePlaceholder: 'Please select your schedule notification time',
		recipients: 'Recipients',
		allUsers: 'All users',
		specificUsers: 'Specific users',
		emailPlaceholder: 'Please enter list emails',
		emailNote: 'Each email address is separated by comma',
		emailValidateMessage: 'Email is required',
	},
	inAppProducts: {
		inAppProductsTitle: 'In-app Products',
		name: 'Name',
		productId: 'Product Id',
		price: 'Price',
		deletesInAppProductConfirmMessage:
			'Do you really want to delete this product?',
	},
	addAndEditInAppProductModal: {
		addInAppProductModalTitle: 'Add In-app Product',
		updateInAppProductModalTitle: 'Update In-app Product',
		nameLabel: 'Name',
		nameValidateMessage: 'Name is required',
		namePlaceholder: 'Please enter your product name',
		productIdLabel: 'Product Id',
		productIdValidateMessage: 'Product Id is required',
		productIdPlaceholder: 'Please enter your product id',
		priceLabel: 'Price',
		priceValidateMessage: 'Price is required',
		pricePlaceholder: 'Please enter your product price',
	},
	headlines: {
		headlinesTitle: 'Headlines',
		type: 'Type',
		headline: 'Headline',
	},
	addAndEditHeadLineModal: {
		addHeadLineModalTitle: 'Add Headline',
		updateHeadLineModalTitle: 'Update Headline',
		type: 'Type',
		headlineLabel: 'Headline',
		headlineValidateMessage: 'Headline name is required',
		headlinePlaceholder: 'Please enter your headline name',
	},
	audioBooksBulkUploadModal: {
		modalTitle: 'Bulk Upload',
	},
};

export default english;
