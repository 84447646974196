import React from 'react';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import { Drawer, DrawerContent } from '@/components/mdc/Drawer';
import { ListItemText, ListItem } from '@/components/mdc/List';
import Link from '@/components/Link';

import { asideTitle, asideBgColorName } from '@/configs';

import { validatePassword } from '@/functions/helper';

import { Connect, connect } from '@/lib/redux-bundler-react';

import * as languages from '@/languages';

import ChangePasswordModal from '@/modules/Modals/ChangePasswordModal';

import styles from '@/App.module.scss';

class Aside extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: this.initialChangePasswordState(),
			changePasswordModalVisible: false,
			isSubmiting: false,
			modalVisible: false,
			errors: {},
		};
	}

	initialChangePasswordState = () => {
		return {
			currentPassword: '',
			newPassword: '',
			confirmNewPassword: '',
		};
	};

	onHandleChangeChangePasswordInfos = ({ key, value }) => {
		const { errors, data } = this.state;

		data[key] = value;

		if (key === 'confirmNewPassword') {
			errors[key] =
				value.length === 0 ||
				data.newPassword !== value ||
				!validatePassword(value);
		} else {
			errors[key] = value.length === 0 || !validatePassword(value);
		}

		this.setState({ errors, data });
	};

	onValidateChangePasswordInfos = data => {
		const { errors } = this.state;

		let check = true;

		if (
			isEmpty(data.currentPassword) ||
			!validatePassword(data.currentPassword)
		) {
			errors.currentPassword = true;
			check = false;
		} else {
			errors.currentPassword = false;
		}

		if (isEmpty(data.newPassword) || !validatePassword(data.newPassword)) {
			errors.newPassword = true;
			check = false;
		} else {
			errors.newPassword = false;
		}

		if (
			isEmpty(data.confirmNewPassword) ||
			!validatePassword(data.confirmNewPassword) ||
			data.confirmNewPassword !== data.newPassword
		) {
			errors.confirmNewPassword = true;
			check = false;
		} else {
			errors.confirmNewPassword = false;
		}

		this.setState({ errors });

		return check;
	};

	handleSubmit = () => {
		const { data } = this.state;

		if (this.onValidateChangePasswordInfos(data)) {
			const { doUserChangePassword } = this.props;

			this.setState({ isSubmiting: true });

			doUserChangePassword({
				oldPassword: data.currentPassword,
				newPassword: data.newPassword,
			}).then(res => {
				if (res.code !== 200) {
					toast.error(res.message);
				} else {
					toast.success(res.message);
				}

				this.setState(preState => ({
					...preState,
					isSubmiting: false,
					errors: {},
					data:
						res.code === 200
							? this.initialChangePasswordState()
							: preState.data,
					modalVisible: res.code !== 200,
				}));
			});
		}
	};

	onModalCancelClick = modalVisible =>
		this.setState(preState => ({
			...preState,
			modalVisible,
			data: {
				...preState.data,
				...this.initialChangePasswordState(),
			},
			errors: {},
		}));

	onChangePasswordClick = () => this.setState({ modalVisible: true });

	render() {
		const { modalVisible, errors, isSubmiting, data } = this.state;

		return (
			<>
				<Connect
					to={[
						'selectAppDrawerOpen',
						'selectInfoLogin',
						'selectLanguageSelected',
					]}
				>
					{({ appDrawerOpen, infoLogin, languageSelected }) => {
						const { profile = {} } = infoLogin;
						const { firstName = '', lastName = '', avatar = null } = profile;

						return (
							<>
								<Drawer
									persistent
									open={appDrawerOpen}
									className={styles['app-drawer']}
								>
									<DrawerContent className="p-0 min-h-full text-white bg-outer-space">
										<div
											className={`bg-${asideBgColorName} flex justify-center items-center`}
											style={{ height: '60px' }}
										>
											<Link
												className="text-2xl font-bold no-underline text-white"
												href="/"
											>
												{asideTitle}
											</Link>
										</div>
										<div className="flex items-center px-4 py-6">
											{avatar ? (
												<img
													src={avatar}
													alt=""
													className="block w-12 h-12 rounded-full mr-4"
												/>
											) : (
												<div className="w-12 h-12 rounded-full mr-4 border flex justify-center items-center bg-grey-dark">
													{firstName.slice(0, 1)}
												</div>
											)}
											<div>
												<p className="text-white font-semibold text-lg">
													{`${firstName} ${lastName}`}
												</p>
												<div className="text-sm flex items-center">
													<div className="bg-green w-3 h-3 rounded-full mr-1" />
													{languages[languageSelected].commons.userLoginStatus}
												</div>
											</div>
										</div>
										<ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
											<ListItemText className="text-xs">
												{languages[languageSelected].leftMenu.manageUsers}
											</ListItemText>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/users"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.users}
											</Link>
										</ListItem>
										<ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
											<ListItemText className="text-xs">
												{languages[languageSelected].leftMenu.digitalContents}
											</ListItemText>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/music-albums"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.music}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/audio-books"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.audioBooks}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/karaokes"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.karaoke}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/video-albums"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.video}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/song-books"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.songBooks}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/picture-books"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.pictureBooks}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/in-app-products"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.inAppProducts}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/headlines"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.headlines}
											</Link>
										</ListItem>
										<ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
											<ListItemText className="text-xs">
												{languages[languageSelected].leftMenu.manageEvents}
											</ListItemText>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/events"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.events}
											</Link>
										</ListItem>
										<ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
											<ListItemText className="text-xs">
												{languages[languageSelected].leftMenu.notifications}
											</ListItemText>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/sent-notifications"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.sentNotifications}
											</Link>
										</ListItem>
										<ListItem className="p-0">
											<Link
												href="/scheduled-notifications"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{
													languages[languageSelected].leftMenu
														.scheduledNotifications
												}
											</Link>
										</ListItem>
										<ListItem className="border-l-4 border-transparent bg-shark text-blue-bayoux py-2 h-auto uppercase">
											<ListItemText className="text-xs">
												{languages[languageSelected].leftMenu.settings}
											</ListItemText>
										</ListItem>
										{/* <ListItem className="p-0">
											<Link
												href="/system-settings"
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
											>
												{languages[languageSelected].leftMenu.systemSettings}
											</Link>
										</ListItem> */}
										<ListItem className="p-0">
											<div
												className="flex items-center w-full px-4 py-3 h-full cursor-pointer border-l-4 border-transparent text-b8c7ce hover:border-butterfly-bus no-underline"
												activeClassName="bg-outer-space-light border-butterfly-bus"
												onClick={() => this.onChangePasswordClick()}
											>
												{languages[languageSelected].leftMenu.changePassword}
											</div>
										</ListItem>
									</DrawerContent>
								</Drawer>
							</>
						);
					}}
				</Connect>
				<ChangePasswordModal
					{...{ modalVisible, errors, isSubmiting, data }}
					onHandleChangeChangePasswordInfos={
						this.onHandleChangeChangePasswordInfos
					}
					onModalOkClick={this.handleSubmit}
					onModalCancelClick={this.onModalCancelClick}
				/>
			</>
		);
	}
}

export default connect('doUserChangePassword', Aside);
