const initialState = {
	languageSelected: 'english',
};

export default {
	name: 'language',
	persistActions: ['UPDATE_LANGUAGE_SELECTED'],
	getReducer: () => {
		return (state = initialState, { type, payload }) => {
			if (type === 'UPDATE_LANGUAGE_SELECTED') {
				return Object.assign({}, state, { languageSelected: payload || null });
			}
			return state;
		};
	},
	selectLanguageSelected: state => state.language.languageSelected,
	doChangeLanguage: lang => ({ dispatch }) => {
		return dispatch({ type: 'UPDATE_LANGUAGE_SELECTED', payload: lang });
	},
};
