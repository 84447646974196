import { toast } from 'react-toastify';

const bundle = {
	name: 'musicAlbums',
	doFetchMusicAlbums: params => ({ core }) => {
		return core.fetchMusicAlbums(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchMusicAlbumDetail: id => ({ core }) => core.fetchMusicAlbumDetail(id),
	doAddAndUpdateMusicAlbum: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddMusicAlbum(params.data);
		}

		return core.doUpdateMusicAlbum(params.data);
	},
	doDeleteMusicAlbum: id => ({ core }) => core.doDeleteMusicAlbum(id),
	doAddAndUpdateMusicFileSong: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddMusicFileSong(params.data);
		}

		return core.doUpdateMusicFileSong(params.data);
	},
};

export default bundle;
