import { toast } from 'react-toastify';

const bundle = {
	name: 'headlines',
	doFetchHeadLines: params => ({ core }) => {
		return core.fetchHeadLines(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doAddAndUpdateHeadLine: params => ({ core }) => {
		return core.doUpdateHeadLine(params.data);
	},
};

export default bundle;
