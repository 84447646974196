import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'redux-bundler-react';
import { Provider as UrqlProvider } from 'urql';
import { RMWCProvider } from 'rmwc/Provider';

import './styles';
import App from './App';
import getStore from './bundles';
import cache from './utils/cache';

const render = ({ store }) => {
	ReactDOM.render(
		<ReduxProvider store={store}>
			<UrqlProvider>
				<RMWCProvider buttonDefaultRipple={false} listItemDefaultRipple={false}>
					<App />
				</RMWCProvider>
			</UrqlProvider>
		</ReduxProvider>,
		document.getElementById('root'),
	);
};

// this is entirely optional, but here we here we try to pull starting data
// from our local cache. We're using a util called money-clip here that
// will only return if the version number is a match and it's not
// older than the specified maxAge.
cache.getAll().then(initialData => {
	if (initialData) {
		console.log('starting with locally cache data:', initialData);
	}
	render({ store: getStore(initialData) });
});
