import { composeBundles, createCacheBundle } from 'redux-bundler';
import { getConfiguredCache } from 'money-clip';

import routes from './routes';
import redirects from './redirects';
import extraArgs from './extraArgs';
import login from './login';
import appDrawer from './appDrawer';
import users from './users';
import digitalContents from './digitalContents';
import fileUpload from './fileUpload';
import digitalContentCategories from './digitalContentCategories';
import eventCategories from './eventCategories';
import events from './events';
import eventTickets from './eventTickets';
import language from './language';
import musicAlbums from './musicAlbums';
import audioBooks from './audioBooks';
import karaokeSongs from './karaokeSongs';
import videoAlbums from './videoAlbums';
import songBooks from './songBooks';
import pictureBooks from './pictureBooks';
import notifications from './notifications';
import inAppProducts from './inAppProducts';
import headlines from './headlines';
import eventImages from './eventImages';

const cache = getConfiguredCache({
	version: 1,
});

export default composeBundles(
	createCacheBundle(cache.set),
	extraArgs,
	routes,
	redirects,
	login,
	appDrawer,
	users,
	digitalContents,
	fileUpload,
	digitalContentCategories,
	eventCategories,
	events,
	eventTickets,
	language,
	musicAlbums,
	audioBooks,
	karaokeSongs,
	videoAlbums,
	songBooks,
	pictureBooks,
	notifications,
	inAppProducts,
	headlines,
	eventImages,
);
