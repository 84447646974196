import { toast } from 'react-toastify';

const bundle = {
	name: 'digitalContentCategories',
	doFetchDigitalContentCategories: () => ({ core }) => {
		return core.fetchDigitalContentCategories().then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doAddAndUpdateDigitalContentCategory: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddDigitalContentCategory({ body: params.data });
		}
		return core.doUpdateDigitalContentCategory({ body: params.data });
	},
	doDeleteDigitalContentCategory: id => ({ core }) => {
		return core.doDeleteDigitalContentCategory(id);
	},
	doGetDigitalContentCategoryDetail: id => ({ core }) => {
		return core.fetchDigitalContentCategory(id);
	},
};

export default bundle;
