const bundle = {
	name: 'eventTickets',
	doAddAndUpdateEventTicket: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddEventTicket({ body: params.data });
		}
		return core.doUpdateEventTicket({ body: params.data });
	},
	doDeleteEventTicket: id => ({ core }) => {
		return core.doDeleteEventTicket(id);
	},
};

export default bundle;
