const initialState = { open: true };

export default {
	name: 'appDrawer',
	getReducer: () => {
		return (state = initialState, { type, payload }) => {
			if (type === 'APP_DRAWER_UPDATE') {
				return Object.assign({}, state, { ...payload });
			}
			return state;
		};
	},
	selectAppDrawerOpen: state => state.appDrawer.open,
	doUpdateAppDrawer: payload => ({ type: 'APP_DRAWER_UPDATE', payload }),
	doToggleAppDrawer: () => ({ dispatch, store }) => {
		dispatch({
			type: 'APP_DRAWER_UPDATE',
			payload: { open: !store.selectAppDrawerOpen() },
		});
	},
	doOpenAppDrawer: () => ({
		type: 'APP_DRAWER_UPDATE',
		payload: { open: true },
	}),
	doCloseAppDrawer: () => ({
		type: 'APP_DRAWER_UPDATE',
		payload: { open: false },
	}),
};
