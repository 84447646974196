import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { connect } from '@/lib/redux-bundler-react';
import getType from '@/utils/getType';

export function getRawUrl(path) {
	if (getType(path) === 'Object') {
		const { pathname, query, hash } = path;
		const queryString = new URLSearchParams(query).toString();
		const hashString = new URLSearchParams(hash).toString();
		return `${pathname}${queryString ? '?' + queryString : ''}${
			hashString ? '#' + hashString : ''
		}`;
	}
	return path.toString();
}

class Link extends PureComponent {
	render() {
		const {
			href = '#',
			className = '',
			activeClassName = '',
			exact = false,
			children,
			pathname,
			onClick = () => {},
			target = null,
			...rest
		} = this.props;
		// exact requires full match
		const rawUrl = getRawUrl(href);
		const active = exact ? href === pathname : pathname.startsWith(rawUrl);

		return (
			<a
				{...{ target }}
				href={rawUrl}
				className={active ? className + ' ' + activeClassName : className}
				onClick={onClick}
			>
				{getType(children) === 'Function'
					? children({ ...rest, active })
					: children}
			</a>
		);
	}
}

Link.propTypes = {
	href: PropTypes.string,
	className: PropTypes.string,
	activeClassName: PropTypes.string,
	exact: PropTypes.bool,
	onClick: PropTypes.func,
};

export default connect('selectPathname', Link);
