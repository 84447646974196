import { toast } from 'react-toastify';
import axios from 'axios';

import apiUrls from '@/constants/apiUrls';

import fetch from '@/utils/fetch';
import { stringify } from '@/utils/helpers';

class CoreService {
	constructor({ store }) {
		this.store = store;
	}

	getToken = () =>
		this.store.selectUserLoginToken() || this.store.selectUserLoginTokenData();
	sessionInvalid = () => this.store.doLogout();
	redirects = () => this.store.doUpdateUrl({ pathname: '/not-allow' });

	fetch = (path = '/', method = 'GET', { body, params, ...opts } = {}) => {
		return fetch(`${process.env.CORE_API_ENDPOINT}${path}`, {
			method,
			headers: {
				Accept: 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Content-Type': 'application/json; charset=utf-8',
				Authorization: `Bearer ${this.getToken()}`,
				isMobile: false,
				...opts.headers,
			},
			...opts,
			body: body ? JSON.stringify(body) : null,
		}).then(resp => {
			if (resp.status !== 200) {
				toast.error('Error');
			}

			return resp.json ? resp.json() : resp;
		});
	};

	callHTTP = ({
		path = '/',
		method = 'GET',
		headers = {},
		params = {},
		data = null,
	}) => {
		console.log('====> callHTTP() ===> ', path, method, params, data);
		return new Promise(resolve => {
			const options = {
				method,
				url: `${process.env.CORE_API_ENDPOINT}${path}${
					Object.keys(params).length > 0 ? '?' + stringify(params) : ''
				}`,
				headers: {
					Accept: 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json; charset=utf-8',
					Authorization: `Bearer ${this.getToken()}`,
					isMobile: false,
				},
			};

			if (Object.keys(headers).length > 0) {
				options.headers = {
					headers: {
						...options.headers,
						...headers,
					},
				};
			}

			if (data) {
				options.data = data;
			}

			axios(options)
				.then(res => {
					if (res.status === 200) {
						if (res.data.code !== 200) {
							toast.error(res.message);
						}

						return resolve(res.data);
					}
				})
				.catch(error => {
					console.error('callHTTP() ERROR', error);
					if (error.response && error.response.status === 400) {
						const dataRes = error.response.data;
						console.log('callHttp Error', dataRes);

						if (dataRes) {
							resolve(dataRes);
							if (dataRes.error) {
								toast.error(dataRes.error);
							}
						}
					}
				});
		});
	};

	// ====== USER FUNCS====== //
	doLogin = body => {
		return this.fetch(apiUrls.user.login, 'POST', { body });
	};

	doUserLogin = (body) => {
		return this.fetch(apiUrls.user.userLogin, 'POST', { body });
	};

	doDeActiveUser = (body = {}) => {
		return this.fetch(apiUrls.user.deActiveUser, 'DELETE', { body });
	};

	doLogout = body => {
		return this.fetch(apiUrls.user.logout, 'POST', { body });
	};

	fetchUsers = params => {
		return this.fetch(`${apiUrls.user.getUsers}?${stringify(params)}`);
	};

	fetchUserDetail = id => {
		return this.fetch(`${apiUrls.user.user}/${id}`);
	};

	doAddUser = opts => {
		return this.fetch(apiUrls.user.user, 'POST', opts);
	};

	doUpdateUser = opts => {
		return this.fetch(`${apiUrls.user.user}/${opts.body.id}`, 'PUT', opts);
	};

	doDeleteUser = id => {
		return this.fetch(`${apiUrls.user.user}/${id}`, 'DELETE');
	};

	doUserForgotPassword = opts => {
		return this.fetch(apiUrls.user.forgotPassword, 'POST', opts);
	};

	doUserResetPassword = opts => {
		return this.fetch(apiUrls.user.resetPassword, 'POST', opts);
	};

	doUserChangePassword = opts => {
		return this.fetch(apiUrls.user.changePassword, 'PUT', opts);
	};

	// ====== DIGITAL CONTENT CATEGORY FUNCS====== //
	fetchDigitalContentCategories = () => {
		return this.fetch(apiUrls.digitalContents.getCategories);
	};

	doAddDigitalContentCategory = opts => {
		return this.fetch(apiUrls.digitalContents.category, 'POST', opts);
	};

	doUpdateDigitalContentCategory = opts => {
		return this.fetch(
			`${apiUrls.digitalContents.category}/${opts.body.id}`,
			'PUT',
			opts,
		);
	};

	doDeleteDigitalContentCategory = id => {
		return this.fetch(`${apiUrls.digitalContents.category}/${id}`, 'DELETE');
	};

	fetchDigitalContentCategory = id => {
		return this.fetch(`${apiUrls.digitalContents.category}/${id}`);
	};

	// ====== DIGITAL CONTENT FUNCS====== //
	fetchDigitalContents = params => {
		return this.fetch(
			`${apiUrls.digitalContents.getContents}?${stringify(params)}`,
		);
	};

	doAddDigitalContent = opts => {
		return this.fetch(apiUrls.digitalContents.content, 'POST', opts);
	};

	doUpdateDigitalContent = opts => {
		return this.fetch(
			`${apiUrls.digitalContents.content}/${opts.body.id}`,
			'PUT',
			opts,
		);
	};

	// ====== FILE  FUNCS====== //
	doUploadFile = data => {
		return axios
			.post(`${process.env.CORE_API_ENDPOINT}${apiUrls.file.upload}`, data, {
				headers: {
					Accept: 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json; charset=utf-8',
					Authorization: `Bearer ${this.getToken()}`,
					isMobile: false,
				},
			})
			.then(resp => {
				if (resp.status !== 200) {
					toast.error('Error');
				}

				return resp.data;
			});
	};

	doUploadAudioFile = data => {
		return axios
			.post(
				`${process.env.CORE_API_ENDPOINT}${apiUrls.file.audioFileUpload}`,
				data,
				{
					headers: {
						Accept: 'application/json',
						'Access-Control-Allow-Origin': '*',
						'Content-Type': 'application/json; charset=utf-8',
						Authorization: `Bearer ${this.getToken()}`,
						isMobile: false,
					},
				},
			)
			.then(resp => {
				if (resp.status !== 200) {
					toast.error('Error');
				}

				return resp.data;
			});
	};

	doDeleteAudioFile = id => {
		return this.fetch(`${apiUrls.file.audioFiledelete}/${id}`, 'DELETE');
	};

	// ====== EVENT CATEGORY FUNCS====== //
	fetchEventCategories = () => {
		return this.fetch(apiUrls.events.getEventCategories);
	};

	doAddEventCategory = opts => {
		return this.fetch(apiUrls.events.eventCategory, 'POST', opts);
	};

	doUpdateEventCategory = opts => {
		return this.fetch(
			`${apiUrls.events.eventCategory}/${opts.body.id}`,
			'PUT',
			opts,
		);
	};

	doDeleteEventCategory = id => {
		return this.fetch(`${apiUrls.events.eventCategory}/${id}`, 'DELETE');
	};

	fetchEventCategory = id => {
		return this.fetch(`${apiUrls.events.eventCategory}/${id}`);
	};

	// ====== EVENT FUNCS====== //
	fetchEvents = params => {
		return this.fetch(`${apiUrls.events.getEvents}?${stringify(params)}`);
	};

	doAddEvent = opts => {
		return this.fetch(apiUrls.events.event, 'POST', opts);
	};

	fetchEventDetail = id => {
		return this.fetch(`${apiUrls.events.event}/${id}`);
	};

	doUpdateEvent = opts => {
		return this.fetch(`${apiUrls.events.event}/${opts.body.id}`, 'PUT', opts);
	};

	doDeleteEvent = id => {
		return this.fetch(`${apiUrls.events.event}/${id}`, 'DELETE');
	};

	// ====== EVENT TICKET FUNCS====== //
	doAddEventTicket = opts => {
		return this.fetch(apiUrls.eventTickets.eventTicket, 'POST', opts);
	};

	doUpdateEventTicket = opts => {
		return this.fetch(
			`${apiUrls.eventTickets.eventTicket}/${opts.body.id}`,
			'PUT',
			opts,
		);
	};

	doDeleteEventTicket = id => {
		return this.fetch(`${apiUrls.eventTickets.eventTicket}/${id}`, 'DELETE');
	};

	// ====== MUSIC ALBUM FUNCS====== //
	fetchMusicAlbums = params => {
		return this.fetch(
			`${apiUrls.musicAlbums.getMusicAlbums}?${stringify(params)}`,
		);
	};

	fetchMusicAlbumDetail = id => {
		return this.fetch(`${apiUrls.musicAlbums.musicAlbum}/${id}`);
	};

	doAddMusicAlbum = data => {
		return this.callHTTP({
			path: apiUrls.musicAlbums.musicAlbum,
			method: 'POST',
			data,
		});
	};

	doUpdateMusicAlbum = data => {
		return this.callHTTP({
			path: `${apiUrls.musicAlbums.musicAlbum}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	doDeleteMusicAlbum = id => {
		return this.fetch(`${apiUrls.musicAlbums.musicAlbum}/${id}`, 'DELETE');
	};

	doAddMusicFileSong = data => {
		return this.callHTTP({
			path: apiUrls.file.audioFileUpload,
			method: 'POST',
			data,
		});
	};

	doUpdateMusicFileSong = data => {
		return this.callHTTP({
			path: `${apiUrls.file.audioFileUpload}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	// ====== AUDIO BOOK FUNCS====== //
	fetchAudioBooks = params => {
		return this.fetch(
			`${apiUrls.audioBooks.getAudioBooks}?${stringify(params)}`,
		);
	};

	fetchAudioBookDetail = id => {
		return this.fetch(`${apiUrls.audioBooks.audioBook}/${id}`);
	};

	doAddAudioBook = data => {
		return this.callHTTP({
			path: apiUrls.audioBooks.audioBook,
			method: 'POST',
			data,
		});
	};

	doUpdateAudioBook = data => {
		return this.callHTTP({
			path: `${apiUrls.audioBooks.audioBook}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	doDeleteAudioBook = id => {
		return this.fetch(`${apiUrls.audioBooks.audioBook}/${id}`, 'DELETE');
	};

	doAddAudioBookAlbumFile = data => {
		return this.callHTTP({
			path: apiUrls.file.audioFileUpload,
			method: 'POST',
			data,
		});
	};

	doUpdateAudioBookAlbumFile = data => {
		return this.callHTTP({
			path: `${apiUrls.file.audioFileUpload}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	// ====== KARAOKE SONG FUNCS====== //
	fetchKaraokeSongs = params => {
		return this.fetch(
			`${apiUrls.karaokeSongs.getKaraokeSongs}?${stringify(params)}`,
		);
	};

	fetchKaraokeSongDetail = id => {
		return this.fetch(`${apiUrls.karaokeSongs.karaokeSong}/${id}`);
	};

	doAddKaraokeSong = data => {
		return this.callHTTP({
			path: apiUrls.karaokeSongs.karaokeSong,
			method: 'POST',
			data,
		});
	};

	doUpdateKaraokeSong = data => {
		return this.callHTTP({
			path: `${apiUrls.karaokeSongs.karaokeSong}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	doDeleteKaraokeSong = id => {
		return this.callHTTP({
			path: `${apiUrls.karaokeSongs.karaokeSong}/${id}`,
			method: 'DELETE',
		});
	};

	// ====== VIDEO ALBUM FUNCS====== //
	fetchVideoAlbums = params => {
		return this.callHTTP({
			path: `${apiUrls.videoAlbums.getVideoAlbums}?${stringify(params)}`,
		});
	};

	fetchVideoAlbumDetail = id => {
		return this.fetch(`${apiUrls.videoAlbums.videoAlbum}/${id}`);
	};

	doAddVideoAlbum = data => {
		return this.callHTTP({
			path: apiUrls.videoAlbums.videoAlbum,
			method: 'POST',
			data,
		});
	};

	doUpdateVideoAlbum = data => {
		return this.callHTTP({
			path: `${apiUrls.videoAlbums.videoAlbum}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	doDeleteVideoAlbum = id => {
		return this.callHTTP({
			path: `${apiUrls.videoAlbums.videoAlbum}/${id}`,
			method: 'DELETE',
		});
	};

	doAddVideoFile = data => {
		return this.callHTTP({
			path: apiUrls.file.videoFile,
			method: 'POST',
			data,
		});
	};

	doUpdateVideoFile = data => {
		return this.callHTTP({
			path: `${apiUrls.file.videoFile}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	// ====== VIDEO ALBUM FUNCS====== //
	doUploadVideoFile = data => {
		return this.callHTTP({
			path: apiUrls.file.videoFile,
			method: 'POST',
			data,
		});
	};

	doDeleteVideoFile = id => {
		return this.callHTTP({
			path: `${apiUrls.file.videoFile}/${id}`,
			method: 'DELETE',
		});
	};

	// ====== SONG BOOK FUNCS====== //
	fetchSongBooks = params => {
		return this.callHTTP({
			path: `${apiUrls.songBooks.getSongBooks}?${stringify(params)}`,
		});
	};

	fetchSongBookDetail = id => {
		return this.callHTTP({ path: `${apiUrls.songBooks.songBook}/${id}` });
	};

	doAddSongBook = data => {
		return this.callHTTP({
			path: apiUrls.songBooks.songBook,
			method: 'POST',
			data,
		});
	};

	doUpdateSongBook = data => {
		return this.callHTTP({
			path: `${apiUrls.songBooks.songBook}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	doDeleteSongBook = id => {
		return this.callHTTP({
			path: `${apiUrls.songBooks.songBook}/${id}`,
			method: 'DELETE',
		});
	};

	// ====== PICTURE BOOK FUNCS====== //
	fetchPictureBooks = params => {
		return this.callHTTP({
			path: `${apiUrls.pictureBooks.getPictureBooks}?${stringify(params)}`,
		});
	};

	fetchPictureBookDetail = id => {
		return this.callHTTP({ path: `${apiUrls.pictureBooks.pictureBook}/${id}` });
	};

	doAddPictureBook = data => {
		return this.callHTTP({
			path: apiUrls.pictureBooks.pictureBook,
			method: 'POST',
			data,
		});
	};

	doUpdatePictureBook = data => {
		return this.callHTTP({
			path: `${apiUrls.pictureBooks.pictureBook}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	doDeletePictureBook = id => {
		return this.callHTTP({
			path: `${apiUrls.pictureBooks.pictureBook}/${id}`,
			method: 'DELETE',
		});
	};

	// ====== IN-APP PRODUCTS FUNCS====== //
	fetchInAppProducts = params => {
		return this.callHTTP({
			path: `${apiUrls.products.getProducts}?${stringify(params)}`,
		});
	};

	fetchInAppProductDetail = id => {
		return this.callHTTP({ path: `${apiUrls.products.product}/${id}` });
	};

	doAddInAppProduct = data => {
		console.log('doAddInAppProduct() ===> ', data);
		return this.callHTTP({
			path: apiUrls.products.product,
			method: 'POST',
			data,
		});
	};

	doUpdateInAppProduct = data => {
		return this.callHTTP({
			path: `${apiUrls.products.product}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	doDeleteInAppProduct = id => {
		return this.callHTTP({
			path: `${apiUrls.products.product}/${id}`,
			method: 'DELETE',
		});
	};

	// ====== HEADLINES FUNCS====== //
	fetchHeadLines = params => {
		return this.callHTTP({
			path: `${apiUrls.headlines.getHeadlines}?${stringify(params)}`,
		});
	};

	doUpdateHeadLine = data => {
		return this.callHTTP({
			path: `${apiUrls.headlines.headline}/${data.get('id')}`,
			method: 'PUT',
			data,
		});
	};

	// ====== NOTIFICATION FUNCS====== //
	fetchNotifications = params => {
		return this.callHTTP({
			path: `${apiUrls.notifications.getNotifications}?${stringify(params)}`,
		});
	};

	doAddNotification = data => {
		return this.callHTTP({
			path: apiUrls.notifications.notification,
			method: 'POST',
			data,
		});
	};

	doUpdateNotification = (data, id) => {
		return this.callHTTP({
			path: `${apiUrls.notifications.notification}/${id}`,
			method: 'PUT',
			data,
		});
	};

	doDeleteNotification = id => {
		return this.callHTTP({
			path: `${apiUrls.notifications.notification}/${id}`,
			method: 'DELETE',
		});
	};

	getEventImages = () => {
		return this.callHTTP({
			path: apiUrls.eventImage.getEventImages,
		});
	};

	doAddEventImage = data => {
		return this.callHTTP({
			path: apiUrls.eventImage.eventImage,
			method: 'POST',
			data,
		});
	};

	doDeleteEventImage = id => {
		return this.callHTTP({
			path: `${apiUrls.eventImage.eventImage}/${id}`,
			method: 'DELETE',
		});
	};
}

export default CoreService;
