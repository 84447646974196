const bundle = {
	name: 'fileUpload',
	doUploadFile: (file, name) => ({ core }) => {
		const formData = new FormData();

		formData.append(name, file);

		return core.doUploadFile(formData);
	},
	doUploadAudioFile: data => ({ core }) => {
		return core.doUploadAudioFile(data);
	},
	doDeleteAudioFile: id => ({ core }) => {
		return core.doDeleteAudioFile(id);
	},
	doUploadVideoFile: data => ({ core }) => {
		return core.doUploadVideoFile(data);
	},
	doDeleteVideoFile: id => ({ core }) => {
		return core.doDeleteVideoFile(id);
	},
};

export default bundle;
