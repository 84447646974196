import React from 'react';
import navHelper from 'internal-nav-helper';
import cn from 'classnames';
import { ToastContainer } from 'react-toastify';

import { connect } from '@/lib/redux-bundler-react';

import Header from '@/modules/Header';
import Aside from '@/modules/Aside';

import * as pages from '@/pages';

import styles from './App.module.scss';

const App = ({ doUpdateUrl, route }) => {
	let Page;
	if (route) {
		const pageName = route.name;
		Page = pages[pageName];
	} else {
		Page = () => null;
	}

	const isPublicPages = [
		'Login',
		'UserLogin',
		'DeActiveUserPage',
		'DeleteAccountInstruction',
	]?.includes(route.name);

	return (
		<div
			className={cn(
				'font-sans leading-normal w-screen max-w-full relative min-h-screen',
				isPublicPages && 'bg-cover bg-center bg-no-repeat',
			)}
			onClick={navHelper(doUpdateUrl)}
			style={
				route.name === 'Login'
					? {
							background: 'url(/images/bg.jpg)',
					  }
					: {}
			}
		>
			{isPublicPages ? (
				<main className="flex h-full relative">
					<Page />
				</main>
			) : (
				<div className="flex min-h-screen overflow-x-hidden">
					<Aside />
					<div className="flex flex-col w-full">
						<Header />
						<main
							className={cn(
								styles['app-content'],
								'flex-auto bg-grey-lighter relative overflow-x-hidden',
							)}
						>
							<Page />
						</main>
					</div>
				</div>
			)}
			<ToastContainer autoClose={5000} />
		</div>
	);
};

export default connect('selectRoute', 'doUpdateUrl', App);
