import { toast } from 'react-toastify';

const bundle = {
	name: 'inAppProducts',
	doFetchInAppProducts: params => ({ core }) => {
		return core.fetchInAppProducts(params).then(res => {
			if (res.code !== 200) {
				toast.error(res.message);
			}

			return res;
		});
	},
	doFetchInAppProductDetail: id => ({ core }) => {
		return core.fetchInAppProductDetail(id);
	},
	doAddAndUpdateInAppProduct: params => ({ core }) => {
		if (params.isCreate) {
			return core.doAddInAppProduct(params.data);
		}
		return core.doUpdateInAppProduct(params.data);
	},
	doDeleteInAppProduct: id => ({ core }) => {
		return core.doDeleteInAppProduct(id);
	},
};

export default bundle;
